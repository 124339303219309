<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="contactEmailEdit"
            :inputComponents="inputComponents"
          >
          </BaseTabGroup>
          <br />
        </div>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 設定ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.setting"
          @click="setting(obj)"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
  </div>
</template>

<script>
import ContactEmailEditInfo from './ContactEmailEditInfo.js'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { SCREEN_ID, SLOT_NAME } from '@/common/const.js'
import { init, setting } from '@/master/department/ContactEmailEditModal.js'

const screenId = SCREEN_ID.CONTACT_MAIL_MODAL_LIST

export default {
  components: {},

  props: {
    selectedItem: {
      type: Object,
    },
    screenKbn: {
      type: Number,
    },
    getDatas: {
      type: Array,
    },
  },
  watch: {
    getDatas() {
      init(this)
    },
  },

  data() {
    return {
      contactEmailEdit: new ContactEmailEditInfo(),
      modalKbn: 0,
      obj: this,
      contactEmailId: null,
    }
  },

  computed: {
    inputComponents() {
      return [
        {
          // タブ
          label: this.detailInfo.tab1.label,
          slot: SLOT_NAME.MARGIN,
          inputComponents: [
            {
              // メールアドレス
              type: 'text',
              id: 'email',
              maxLength: 256,
              columnInfo: this.detailInfo.email,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.EMAIL,
              ],
            },
            {
              // 予備１
              type: 'text',
              id: 'reserve01',
              maxLength: 50,
              columnInfo: this.detailInfo.reserve01,
              rules: [this.INPUT_TYPE_CHECK.TEXT],
            },
          ],
        },
      ]
    },

    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
  },

  methods: {
    getListOptions,
    init,
    openSubModal,
    setting,

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style scoped></style>
