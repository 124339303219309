/**
 * 燃料調整金編集データ.
 * @class
 * @exports
 */
export default class contactEmailEdit {
  contactEmailId = null
  blockCode = null
  email = null
  reserve01 = null
  reserve02 = null
  reserve03 = null
  reserve04 = null
  reserve05 = null
}
