import { DETAIL_SCREEN_KBN } from '@/common/const.js'
import ContactEmailEdit from './ContactEmailEditInfo'

export function init(obj) {
  if (obj.getDatas[0].detailScreenKbn === DETAIL_SCREEN_KBN.NEW) {
    //追加
    obj.contactEmailEdit = new ContactEmailEdit()
  } else {
    if (obj.getDatas[0].detailScreenKbn === DETAIL_SCREEN_KBN.NEW) {
      obj.contactEmailEdit = new ContactEmailEdit()
    } else {
      //編集
      obj.contactEmailEdit.contactEmailId =
        obj.getDatas[0].selectedItem[0].contactEmailId
      obj.contactEmailEdit.email = obj.getDatas[0].selectedItem[0].email
      obj.contactEmailEdit.reserve01 = obj.getDatas[0].selectedItem[0].reserve01
      obj.contactEmailEdit.reserve02 = obj.getDatas[0].selectedItem[0].reserve02
      obj.contactEmailEdit.reserve03 = obj.getDatas[0].selectedItem[0].reserve03
      obj.contactEmailEdit.reserve04 = obj.getDatas[0].selectedItem[0].reserve04
      obj.contactEmailEdit.reserve05 = obj.getDatas[0].selectedItem[0].reserve05
    }
  }
  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export async function setting(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.$bvModal.hide('contact-email-edit')
  obj.$emit('after-close-set', obj.contactEmailEdit)
}
